const
    REFERRAL_CTA_SELECTOR = '[data-ga="referral-cta"]',
    REFERRAL_CALL_SELECTOR = '[data-ga="referral-call"]',
    TAKEOVER_BANNER_HEADER_BTN = document.querySelectorAll('#takeover-notification-banner a.ref-btn'),
    TAKEOVER_BANNER_FOOTER_BTN = document.querySelectorAll('#takeover-notification-banner-footer a.ref-btn'),
    TRENDING_BANNER_BTN = document.querySelectorAll('#trending-banner a'),
    SYSTEM_FINDER_BANNER_CTA = document.querySelectorAll('.finder-cta-btn'),
    QUIZ_CTA_VERSION1 = document.querySelectorAll('.section.version1 a.ref-btn'),
    QUIZ_CTA_VERSION2 = document.querySelectorAll('.section.version2 a.ref-btn'),
    BANNER_CTA = document.querySelectorAll('.banner-cta a'),
    PAGETYPE_FIELD = document.getElementById('pagetype'),
    PRODUCT_BRAND = document.getElementById('product_brand_interest'),
    CATEGORY_FIELD = document.getElementById('productcategory'),
    CUSTOM_FIELD = document.getElementById('customfield1'),
    INTERNAL_LINKS_SELECTOR = '[data-link-type="internal"]',
    EXTERNAL_LINKS_SELECTOR = '[data-link-type="external"]',
    ON_PAGE_LINKS_SELECTOR = '[data-link-type="on-page"]',
    SEARCH_FORM = document.querySelector('.search-form');

function triggerEventTracking() {
    // Get context of page from customfield1
    const context = CUSTOM_FIELD ? CUSTOM_FIELD.value : '',
        pageTypeValue = PAGETYPE_FIELD ? PAGETYPE_FIELD.value : 'generic',
        productBrand = PRODUCT_BRAND ? PRODUCT_BRAND.value : '',
        productCategoryValue = CATEGORY_FIELD ? CATEGORY_FIELD.value : null;

    // Get Product Category of CTA
    function productCategory($element) {
        return $element.dataset.productcategory || productCategoryValue;
    }

    // Get Provider CID of CTA
    function providerCID($element) {
        return $element.dataset.providercid || '0'
    }

    // Get Page Type of CTA
    function pageType($element) {
        return $element.dataset.pagetype || pageTypeValue;
    }

    // Get Product Name of CTA
    function productName($element) {
        return $element.dataset.productname || null;
    }

    // Get Sale Marking of CTA
    function saleCTA($element) {
        return $element.dataset.sale || 'false';
    }

    // Get Element Placement of CTA
    function elementPlacement($element) {
        return $element.dataset.elementplacement || null;
    }

    // Get CTA URL of CTA
    function ctaUrl($element) {
        return $element.href || null
    }

    // Get Index of CTA
    function providerIndex($element) {
        return $element.dataset.order || 'single'
    }

    // Get Destination Type of CTA
    function destinationType($element)
    {
        return $element.dataset.destination || 'generic'
    }

    // Get Element Clicked of CTA
    function elementClicked($element)
    {
        return $element.dataset.elementclicked || ''
    }

    // Get Element Clicked of CTA
    function linkText($element)
    {
        if ($element.innerText !== '') {
            return $element.innerText;
        } else if ($element.innerHTML.includes('img')) {
            return 'image';
        } else {
            return '';
        }
    }

    function getDirection($element)
    {
        return $element.dataset.direction || '';
    }

    // Check if active promotion
    function checkActivePromotion()
    {
        return document.body.classList.contains('promotion-active') || 'false'
    }

    function buildDatalayerParams($element)
    {
        return {
            'href': ctaUrl($element),
            'serviceProvider': productName($element),
            'pageType': pageType($element),
            'productCategory': productCategory($element),
            'activePromotion': saleCTA($element),
            'elementPlacement': elementPlacement($element),
            'position': providerIndex($element),
            'linkText': linkText($element),
            'providerCID': providerCID($element),
        }
    }

    function triggerClickOutAction(elementTarget)
    {
        const defaultData = {
            'event': 'cta_click_out',
            'elementClicked': 'cta button',
            'destinationType': destinationType(elementTarget),
        };
        const dataLayerData = Object.assign(defaultData, buildDatalayerParams(elementTarget));
        window.dataLayer.push(dataLayerData);

        console.log(dataLayerData);

    }

    function triggerCallClickOutAction(elementTarget)
    {
        const defaultData = {
            'event': 'cta_call_click',
            'elementClicked': 'phone link',
            'destinationType': 'provider',
        };
        const dataLayerData = Object.assign(defaultData, buildDatalayerParams(elementTarget));
        window.dataLayer.push(dataLayerData);

        console.log(dataLayerData);

    }

    function triggerInternalExternalClick(elementTarget, linkType)
    {

        const eventAction = linkType === 'internal' ? 'internal_link_click' : 'external_link_click';
        const defaultData = {
            'event': eventAction,
            'elementPlacement': elementPlacement(elementTarget),
            'elementClicked': elementClicked(elementTarget),
            'pageType': pageTypeValue,
            'position': providerIndex(elementTarget),
            'href': ctaUrl(elementTarget),
            'linkText': linkText(elementTarget),
        };
        window.dataLayer.push(defaultData);
        console.log(defaultData);
    }

    function triggerOnPageClick(elementTarget)
    {

        const defaultData = {
            'event': 'on_page_link_click',
            'elementPlacement': elementPlacement(elementTarget),
            'elementClicked': elementClicked(elementTarget),
            'pageType': pageTypeValue,
            'pageUrl': window.location.href,
            'position': providerIndex(elementTarget),
            'linkText': linkText(elementTarget),
            'direction': getDirection(elementTarget),
        };
        window.dataLayer.push(defaultData);

        console.log(defaultData);
    }

    // Referral Call click
    document.addEventListener('click', function (e) {
        const ctaClickout = e.target.closest(REFERRAL_CTA_SELECTOR);
        const callClickout = e.target.closest(REFERRAL_CALL_SELECTOR);
        const internalClickout = e.target.closest(INTERNAL_LINKS_SELECTOR);
        const externalClickout = e.target.closest(EXTERNAL_LINKS_SELECTOR);
        const onPageClickout = e.target.closest(ON_PAGE_LINKS_SELECTOR);

        if (ctaClickout) {
            if (ctaClickout.classList.contains('quiz-cta-btn') === false) {
                triggerClickOutAction(ctaClickout);
            }
        }

        if (callClickout) {
            triggerCallClickOutAction(callClickout);
        }

        if (internalClickout) {
            triggerInternalExternalClick(internalClickout, 'internal');
        }

        if (externalClickout) {
            triggerInternalExternalClick(externalClickout, 'external');
        }

        if (onPageClickout) {
            triggerOnPageClick(onPageClickout);
        }
    });

    // Takeover Banner header cta
    if (TAKEOVER_BANNER_HEADER_BTN) {
        TAKEOVER_BANNER_HEADER_BTN.forEach(btn => {
            btn.addEventListener('click', (e) => {
                const elementTarget = e.currentTarget;
                const defaultData = {
                    'event': 'sponsored_click_out',
                    'elementClicked': 'takeover banner',
                    'destinationType': destinationType(elementTarget),
                    'elementPlacement': 'header',
                    'elementName': 'header Takeover banner',
                    'serviceProvider': null,
                    'pageType': context,
                    'href': ctaUrl(elementTarget),
                };
                window.dataLayer.push(defaultData);

                console.log(defaultData);
            });
        });
    }

    // Trending Banner cta
    if (TRENDING_BANNER_BTN) {
        TRENDING_BANNER_BTN.forEach(btn => {
            btn.addEventListener('click', (e) => {
                const elementTarget = e.currentTarget;
                const defaultData = {
                    'event': 'sponsored_click_out',
                    'elementClicked': 'trending banner',
                    'destinationType': destinationType(elementTarget),
                    'elementPlacement': 'header',
                    'elementName': 'Trending banner link',
                    'serviceProvider': null,
                    'pageType': context,
                    'href': ctaUrl(elementTarget),
                };
                window.dataLayer.push(defaultData);

                console.log(defaultData);
            });
        });
    }

    // Takeover Banner footer cta
    if (TAKEOVER_BANNER_FOOTER_BTN) {
        TAKEOVER_BANNER_FOOTER_BTN.forEach(btn => {
            btn.addEventListener('click', (e) => {
                const elementTarget = e.currentTarget;
                const defaultData = {
                    'event': 'sponsored_click_out',
                    'elementClicked': 'takeover banner',
                    'destinationType': destinationType(elementTarget),
                    'elementPlacement': 'footer',
                    'elementName': 'Footer Takeover banner',
                    'serviceProvider': null,
                    'pageType': context,
                    'href': ctaUrl(elementTarget),
                };
                window.dataLayer.push(defaultData);

                console.log(defaultData);
            });
        });
    }

    // Takeover Banner footer cta
    if (SYSTEM_FINDER_BANNER_CTA) {
        SYSTEM_FINDER_BANNER_CTA.forEach(btn => {
            btn.addEventListener('click', (e) => {
                const elementTarget = e.currentTarget;
                window.dataLayer.push({
                    'event': 'cta_lead_form_click',
                    'elementPlacement': 'in-content',
                    'elementClicked': elementClicked(elementTarget),
                    'pageType': pageTypeValue,
                    'href': ctaUrl(elementTarget),
                });

                console.log({
                    'event': 'cta_lead_form_click',
                    'elementPlacement': 'in-content',
                    'elementClicked': elementClicked(elementTarget),
                    'pageType': pageTypeValue,
                    'href': ctaUrl(elementTarget),
                });
            });
        });
    }

    // Banner cta box within content
    if (BANNER_CTA) {
        BANNER_CTA.forEach(btn => {
            btn.addEventListener('click', (e) => {
                const elementTarget = e.currentTarget;
                window.dataLayer.push({
                    'event': 'cta_click_out',
                    'elementPlacement': 'tip box banner cta',
                    'elementClicked': 'text link',
                    'pageType': pageTypeValue,
                    'href': ctaUrl(elementTarget),
                    'serviceProvider': productBrand || '',
                    'productCategory': productCategoryValue,
                    'activePromotion': checkActivePromotion(),
                    'position': 'single',
                    'linkText': linkText(elementTarget),
                });

                console.log({
                    'event': 'cta_click_out',
                    'elementPlacement': 'tip box banner cta',
                    'elementClicked': 'text link',
                    'pageType': pageTypeValue,
                    'href': ctaUrl(elementTarget),
                    'serviceProvider': productBrand || '',
                    'productCategory': productCategoryValue,
                    'activePromotion': checkActivePromotion(),
                    'position': 'single',
                    'linkText': linkText(elementTarget),
                });
            });
        });
    }

    // Quiz CTA version 1
    if (QUIZ_CTA_VERSION1) {
        QUIZ_CTA_VERSION1.forEach(btn => {
            btn.addEventListener('click', (e) => {
                const elementTarget = e.currentTarget;
                elementTarget.dataset.productname = 'quiz';

                // New push for click out
                const defaultData = {
                    'event': 'cta_click_out',
                    'elementClicked': 'quiz cta version 1',
                    'destinationType': 'internal',
                };
                const dataLayerData = Object.assign(defaultData, buildDatalayerParams(elementTarget));
                window.dataLayer.push(dataLayerData);

                console.log(dataLayerData);

                // New push for lead form entry
                window.dataLayer.push({
                    'event': 'cta_lead_form_click',
                    'elementPlacement': 'in-content',
                    'elementClicked': 'Quiz CTA Version 1',
                    'pageType': pageTypeValue,
                    'href': ctaUrl(elementTarget),
                });

                console.log({
                    'event': 'cta_lead_form_click',
                    'elementPlacement': 'in-content',
                    'elementClicked': 'Quiz CTA Version 1',
                    'pageType': pageTypeValue,
                    'href': ctaUrl(elementTarget),
                });
            });
        });
    }

    // Quiz CTA version 2
    if (QUIZ_CTA_VERSION2) {
        QUIZ_CTA_VERSION2.forEach(btn => {
            btn.addEventListener('click', (e) => {
                const elementTarget = e.currentTarget;
                elementTarget.dataset.productname = 'quiz';

                // New push for click out
                const defaultData = {
                    'event': 'cta_click_out',
                    'elementClicked': 'quiz cta version 2',
                    'destinationType': 'internal',
                };
                const dataLayerData = Object.assign(defaultData, buildDatalayerParams(elementTarget));
                window.dataLayer.push(dataLayerData);

                console.log(dataLayerData);

                // New push for lead form entry
                window.dataLayer.push({
                    'event': 'cta_lead_form_click',
                    'elementPlacement': 'in-content',
                    'elementClicked': 'Quiz CTA Version 2',
                    'pageType': pageTypeValue,
                    'href': ctaUrl(elementTarget),
                });

                console.log({
                    'event': 'cta_lead_form_click',
                    'elementPlacement': 'in-content',
                    'elementClicked': 'Quiz CTA Version 2',
                    'pageType': pageTypeValue,
                    'href': ctaUrl(elementTarget),
                });
            });
        });
    }

    // Search Form
    if (SEARCH_FORM) {
        SEARCH_FORM.addEventListener('submit', () => {
            const defaultData = {
                'event': 'site_search',
                'searchTerm': document.querySelector('.search-field').value || '',
            };
            window.dataLayer.push(defaultData);

            console.log(defaultData);
        });
    }
}

export default function init() {
    triggerEventTracking();
}
