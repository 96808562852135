function triggerLinkTracking()
{

    const avoidElements = [
        '#takeover-notification-banner a.btn',
        '#takeover-notification-banner-footer a.btn',
        '[data-ga="social-share"]',
        '[data-ga="related-article"]',
        '[data-ga="referral-cta"]',
        '[data-ga="referral-call"]',
    ];

    const linkedElements = {
        'header': {
            'logo': '.top-header .navbar-brand',
            'nav': '#main-nav .navbar-nav',
        },
        'header_disclosure': {
            'banner_link': '.disclaimer-wrap .disclaimer-text',
            'secondary_nav': '.disclaimer-wrap .secondary-nav',
        },
        'hero': {
            'author': '.page-hero-meta .author-name',
            'page_meta_social': '.page-hero-meta .article-share',
            'review_product_anchor': '.hero-content .anchor-link',
        },
        'footer': {
            'footer_nav': '.foot-widget .nav',
            'social_footer': '#footer .social-links',
        },
        'content': {
            'citations': '.citation-list',
            'citation-blurb': '.citation-blurb',
            'content-link': '.content-container',
            'how-we-review': '.how-we-review-link',
            'toc': '.toc-menu',
            'faq': '.faqs-list-container',
            'faq-content': '.faqs-list-container .accordion-content',
            'product-anchor': '.top-list-anchors',
            'product_rewards': '#systems-best-awards',
            'related-posts': '.related-posts',
        },
    };

    const isolatedElements = [
        'toc',
        'product-anchor',
    ];

    // const isParentNode = (node, parentNode) => {
    //     return node === parentNode ? false : parentNode.contains(node);
    // }

    const isExternalRegex = (function () {
        const domainRe = /https?:\/\/((?:[\w\d-]+\.)+[\w\d]{2,})/i;

        return (url) => {
            if (typeof url === 'undefined' || url === null || url === '') {
                return false;
            }
            if (url.includes('tel:') || url.includes('mailto:') || url === '#') {
                return false;
            }
            let response = false;
            if (typeof url !== 'undefined' && url !== null) {
                const domain = (url) => {
                    return domainRe.exec(url)[1] || '';
                }
                response = domain(location.href) !== domain(url);
            }
            return response;
        };
    })();

    const setLinkTrackingAttr = (element, location, elementClicked) => {
        if (elementClicked === 'faq') {
            const links = element.querySelectorAll('a.accordion-title');
            let onPageLinkCount = 1;
            links.forEach(function (link) {
                link.setAttribute('data-elementPlacement', location);
                link.setAttribute('data-elementClicked', elementClicked);
                link.setAttribute('data-link-type', 'on-page');
                link.setAttribute('data-order', onPageLinkCount);
                onPageLinkCount++;
            });
        } else if (element.nodeName === 'A') {
            const checkStatus = element.getAttribute('data-skip-tracking');
            if (typeof checkStatus === 'undefined' || checkStatus === null) {
                element.setAttribute('data-elementPlacement', location);
                element.setAttribute('data-elementClicked', elementClicked);
                element.setAttribute('data-order', 'single');
                element.setAttribute('data-link-type', isExternalRegex(element.href) ? 'external' : 'internal');
            }
        } else {
            const links = element.querySelectorAll('a');
            let linkCount = 1,
                onPageLinkCount = 1;
            links.forEach(function (link) {
                const checkStatus = link.getAttribute('data-skip-tracking');
                if (typeof checkStatus === 'undefined' || checkStatus === null) {
                    if (isolatedElements.indexOf(elementClicked) !== -1) {
                        link.setAttribute('data-elementPlacement', location);
                        link.setAttribute('data-elementClicked', elementClicked);
                        link.setAttribute('data-link-type', 'on-page');
                        link.setAttribute('data-order', onPageLinkCount);
                        onPageLinkCount++;
                    } else {
                        if (
                            link.classList.contains('brand') === false ||
                            link.parentNode.classList.contains('social_items') === false
                        ) {
                            link.setAttribute('data-elementPlacement', location);
                            link.setAttribute('data-elementClicked', elementClicked);
                            if (location !== 'in-content') {
                                link.setAttribute('data-order', linkCount);
                            }
                            link.setAttribute('data-link-type', isExternalRegex(link.href) ? 'external' : 'internal');
                            linkCount++;
                        }
                    }
                }
            });
        }
    }

    avoidElements.forEach(function (elements) {
        const queriedElements = document.querySelectorAll(elements);
        if (typeof queriedElements !== 'undefined' && queriedElements !== null) {
            queriedElements.forEach(function (element) {
                element.setAttribute('data-skip-tracking', 'true');
            });
        }
    });

    for (const location in linkedElements) {
        const linkElements = linkedElements[location];
        for (const elementClicked in linkElements) {
            const element = document.querySelector(linkElements[elementClicked]);
            if (typeof element !== 'undefined' && element !== null) {
                setLinkTrackingAttr(element, location, elementClicked);
            }
        }
    }
}

export default function init()
{
    triggerLinkTracking();
}
