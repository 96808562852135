
const redirect = (e) => {
    const url = e.currentTarget.querySelector('a').getAttribute('href');
    window.location.href = url;
}

function turnContainerToLink(containers)
{

    containers.forEach(card => {
        const elems = document.querySelectorAll(card);

        if (typeof elems !== 'undefined' && elems !== null) {
            elems.forEach(elem => {
                elem.addEventListener('click', (e) => redirect(e));
            });
        }
    });
}

export default function init() {
    turnContainerToLink([
        '.posts-list .card',
        '.news-related-posts .card',
        '.articles-hub .card',
        '.link-column .article',
        '.reviews-wrap .reviews-box',
    ]);
}
