function tables() {
    setTimeout(() => {
        document.querySelectorAll('table.basic').forEach((table) => {
            if (!table.parentElement.classList.contains('table-container')) {
                let container = document.createElement('div');
                container.classList.add('table-container');
                table.parentElement.insertBefore(container, table);
                container.appendChild(table);

                table.querySelectorAll('.column1').forEach((column) => {
                    if (column.innerHTML.trim() === '') {
                        column.innerHTML = '<span>&nbsp;</span>';
                    } else {
                        let span = document.createElement('span');
                        span.innerHTML = column.innerHTML;
                        column.innerHTML = '';
                        column.appendChild(span);
                    }
                });
            }

            let tc = table.parentElement;
            if (!tc.parentElement.classList.contains('table-overflow')) {
                let overflow = document.createElement('div');
                overflow.classList.add('table-overflow');
                tc.parentElement.insertBefore(overflow, tc);
                overflow.appendChild(tc);
            }
        });

        checkTableOverflow(); // Run check on load
    }, 300);
}

// Function to check table overflow
function checkTableOverflow() {
    document.querySelectorAll('table.basic').forEach((table) => {
        const tableWidth = table.querySelector('tbody');
        const tableContainer = table.parentElement;

        if (tableWidth.getBoundingClientRect().width > tableContainer.offsetWidth) {
            tableContainer.parentElement.classList.add('show-overflow');
        } else {
            tableContainer.parentElement.classList.remove('show-overflow');
        }
    });
}

// Initialize the script
export default function init() {
    tables();
    window.addEventListener('resize', checkTableOverflow); // Keep resize listener
}