function accordionContent(sectionClick, sectionContent) {
    let pageType = window.sh_pagetype;
    if (window.innerWidth <= 576) {
        if (sectionClick && sectionContent) {
            const sectionHeight = sectionContent.scrollHeight + 'px';

            sectionClick.addEventListener('click', function() {
                sectionClick.classList.toggle('active');
                
                const isOpen = sectionContent.style.height === '0px' || !sectionContent.style.height;

                if (isOpen) {
                    sectionContent.style.height = sectionHeight;
                } else {
                    sectionContent.style.height = '0px';
                }

                const defaultData = {
                    event: 'on_page_link_click',
                    elementPlacement: 'in-content',
                    elementClicked: 'accordion',
                    pageType: pageType,
                    pageUrl: window.location.href,
                    position: 'single',
                    linkText: sectionClick.innerText || 'Accordion Section',
                    direction: isOpen ? 'open' : 'close',
                };

                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push(defaultData);

                console.log(defaultData);
            });
        }
    } else {
        if (sectionContent) {
            sectionContent.style.height = '';
        }
    }
}

function openAccordion() {
    accordionContent(document.querySelector('.system-award-title'), document.querySelector('.scrolling-wrapper-flexbox'));
    accordionContent(document.querySelector('.securescore-rate .score .arrow'), document.querySelector('.bar-rating-bars-wrapper'));
}

export default function init() {
    openAccordion();

    window.addEventListener('resize', function() {
        openAccordion();
    });
}