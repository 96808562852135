import tippy from 'tippy.js';

const TOOLTIPS_SELECTOR = '.has-tooltip';

export default () => {
    const tooltips = Array.prototype.slice.call(document.querySelectorAll(TOOLTIPS_SELECTOR));

    if (tooltips && tooltips.length > 0) {
        tooltipInitializationStep(tooltips);
    }
};

function tooltipInitializationStep(tooltips) {
    const perBatch = 25;
    let currentIndex = 0;
    const tooltipsLen = tooltips.length;
    const possibleBatchEnd = currentIndex + perBatch;
    const batchEnd = possibleBatchEnd >= tooltipsLen ? tooltipsLen : possibleBatchEnd;

    for (currentIndex; currentIndex < batchEnd; currentIndex++) {
        let tooltip = tooltips[currentIndex];
        tippy(tooltip, getTooltipOptions(tooltip));
    }

    if (currentIndex < tooltipsLen) {
        window.requestAnimationFrame(tooltipInitializationStep);
    }
}

function getTooltipOptions(tooltip) {
    let fallbackPlacements = [];

    if (
        tooltip
        && typeof tooltip.dataset.fallbackPlacement !== 'undefined'
        && tooltip.dataset.fallbackPlacement !== ''
    ) {
        fallbackPlacements.push(tooltip.dataset.fallbackPlacement);
    }

    return {
        popperOptions: {
            modifiers: [{
                name: 'flip',
                options: {
                    fallbackPlacements,
                },
            }],
        },
    };
}
