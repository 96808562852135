import MicroModal from 'micromodal';

function arCTA()
{
    document.addEventListener('DOMContentLoaded', () => {
        const isAndroid = /Android/i.test(navigator.userAgent);
        const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
        const isChrome = navigator.userAgent.includes('CriOS');
        const isDesktop = !isAndroid && !isIOS;
        const arContainers = document.querySelectorAll('.ar-cta-container');

        if (arContainers.length === 0) {
            return;
        }

        arContainers.forEach(container => {
            if (!container) {
                return;
            }

            const arLink = container.querySelector('.ar-link');

            if (!arLink) {
                return;
            }

            const androidFile = arLink.getAttribute('data-android') || '#';
            const iosFile = arLink.getAttribute('data-ios') || '#';
            const usdzFile = arLink.getAttribute('data-usdz') || '#';

            try {
                if (isIOS) {
                    if (usdzFile !== '#' && isChrome) {
                        arLink.href = usdzFile;
                    } else if (iosFile !== '#') {
                        arLink.href = iosFile;
                    } else {
                        arLink.removeAttribute('href');
                    }
                    arLink.rel = 'ar';
                } else if (isAndroid) {
                    if (androidFile !== '#') {
                        const encodedFile = encodeURIComponent(androidFile).replace(/[!'()*]/g, escape);
                        arLink.href = `intent://arvr.google.com/scene-viewer/1.0?file=${encodedFile}#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;end;`;
                    } else {
                        arLink.removeAttribute('href');
                    }
                } else if (isDesktop) {
                    arLink.removeAttribute('href');
                } else {
                    arLink.removeAttribute('href');
                }
            } catch (error) {
                arLink.removeAttribute('href');
            }
        });

        function toggleMicroModal()
        {
            const modalTriggers = document.querySelectorAll('a.ar-link[data-micromodal-trigger]');

            if (window.innerWidth < 992) {
                // Disable modal triggers on mobile
                modalTriggers.forEach(trigger => {
                    trigger.removeAttribute('data-micromodal-trigger');
                });
            } else {
                // Restore modal triggers on desktop
                modalTriggers.forEach(trigger => {
                    if (!trigger.hasAttribute('data-micromodal-trigger')) {
                        trigger.setAttribute('data-micromodal-trigger', trigger.dataset.modalId);
                    }
                });

                MicroModal.init();
            }
        }

        // Run on initial load
        toggleMicroModal();

        // Run when the window is resized
        window.addEventListener('resize', toggleMicroModal);
    });
}

export default function init()
{
    arCTA();
}
