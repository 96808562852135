// Expand to show text-only reviews
function openText() {
    var x = document.getElementById('expander');
    var y = document.getElementById('hidden-divs');

    if ((typeof x !== 'undefined' && x !== null) && (typeof y !== 'undefined' && y !== null)) {
        x.addEventListener('click', function (e) {
            e.preventDefault();
            
            y.classList.toggle('expanded');
    
            this.classList.toggle('open');
            if (this.classList.contains('open')) {
                this.innerHTML = 'View Less';
            } else {
                this.innerHTML = 'Expand to View More';
            }
        });
    }

    var a = document.getElementById('modal-expander');
    var b = document.getElementById('submit_question');
    var c = document.querySelectorAll('[data-dismiss="modal"]');

    if ((typeof a !== 'undefined' && a !== null) && (typeof b !== 'undefined' && b !== null)) {
        a.addEventListener('click', function (e) {
            e.preventDefault();

            if (b.classList.contains('show')) {
                b.style.display = 'none';
                b.classList.remove('show');
            } else {
                b.style.display = 'block';
                b.classList.add('show');
            }
        });

        for (let i = 0; i < c.length; i++) {
            c[i].addEventListener('click', function (e) {
                e.preventDefault();
    
                b.style.display = 'none';
                b.classList.remove('show');
            });
        }
    }
}

export default function init()
{
    openText();
}
