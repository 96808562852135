import debounce from '../debounce';

function stickyCTA() {
    const topContent = document.getElementById('page-template'),
        mobileCTA = document.getElementById('mobileCTA');

    if (topContent && mobileCTA) {
        window.addEventListener('scroll', function() {
            let show = topContent.getBoundingClientRect().top;
            if (show <= 0) {
                mobileCTA.classList.add('show');
            } else {
                mobileCTA.classList.remove('show');
            }
        })
    }
}

// JS for flexible layout sticky CTA
function flexStickyCTA() {
    const topFlexible = document.getElementById('flexible-template'),
        flexibleMobileCTA = document.getElementsByClassName('mobile');
    if (topFlexible && flexibleMobileCTA.length > 0) {
        window.addEventListener('scroll', debounce(function() {
            let show = topFlexible.getBoundingClientRect().top;
            if (show <= 0) {
                flexibleMobileCTA[0].classList.add('show');
            } else {
                flexibleMobileCTA[0].classList.remove('show');
            }
        }), 100)
    }
}

function bestStickyCTA() {
    const stickyCards = document.querySelectorAll('.brands-detail-wrapper .list-core .product-card--sticky');

    // Utility function to handle scroll behavior for sticky cards
    function handleScroll(card) {
        const cardTop = card.getBoundingClientRect().top;
        if (cardTop <= 5) {
            card.classList.add('fix-scroll');
        } else {
            card.classList.remove('fix-scroll');
        }

        if (!card.classList.contains('with-promotion')) {
            const promo = card.querySelector('.promotion-container');
            if (promo) {
                card.classList.add('with-promotion');
            }
        }
    }

    // Utility function to update phone CTA text on mobile devices
    function updatePhoneCtaText() {
        stickyCards.forEach(card => {
            const phoneCta = card.querySelector('.phone-cta a');
            if (phoneCta) {
                // Store the original text if it hasn't been stored yet
                if (!phoneCta.dataset.originalText) {
                    phoneCta.dataset.originalText = phoneCta.textContent.trim();
                }
    
                // Update the text based on screen width
                if (window.innerWidth <= 480) {
                    phoneCta.childNodes.forEach(node => {
                        if (node.nodeType === Node.TEXT_NODE && node.textContent.trim() !== '') {
                            node.textContent = ' Contact Us '; // Update the text for mobile
                        }
                    });
                    phoneCta.setAttribute('title', 'Contact Us');
                } else {
                    // Restore the original text when screen width exceeds 576px
                    phoneCta.childNodes.forEach(node => {
                        if (node.nodeType === Node.TEXT_NODE && node.textContent.trim() !== '') {
                            node.textContent = phoneCta.dataset.originalText; // Restore original text
                        }
                    });
                    phoneCta.setAttribute('title', phoneCta.dataset.originalText);
                }
            }
        });
    }

    if (stickyCards.length > 0) {
        // Update phone CTA on initial load and resize
        updatePhoneCtaText();
        window.addEventListener('resize', updatePhoneCtaText);

        // Add scroll event for sticky behavior
        stickyCards.forEach(card => {
            window.addEventListener('scroll', function() {
                handleScroll(card);  // Apply the scroll behavior
            });
        });
    }
}

export default function init() {
    stickyCTA();
    flexStickyCTA();
    bestStickyCTA();
}