// Back to Top trigger

function backToTop() {

    const b2t = document.getElementById('b2t');

    if (b2t) {

        // Scroll to top
        b2t.addEventListener('click', function (e) {

            e.preventDefault();

            window.scrollTo({top: 0, behavior: 'smooth'});

        });

    }

    // resources b2t show
    const header = document.getElementsByClassName('research-header')[0];
    if (header && b2t) {
        window.addEventListener('scroll', function() {
            if (this.scrollY > (header.offsetTop + header.offsetHeight)) {
                b2t.classList.add('show');
            } else {
                b2t.classList.remove('show');
            }
        })
    }

}

export default function init() {
    backToTop();
}
