// core version + navigation, pagination modules:
import Swiper, { Pagination } from 'swiper';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/pagination';

// configure Swiper to use modules
Swiper.use([Pagination]);

// TODO: fix swiper functionality on homepage
// eslint-disable-next-line no-unused-vars
const swiper = new Swiper('.experts-homepage-wrap .swiper-container', {
    slidesPerView: 1,
	pagination: {
		el: '.swiper-pagination',
		clickable: true,
	},
    breakpoints: {
        // when window width is >= 768px
        768: {
            slidesPerView: 2,
            spaceBetween: 30,
        },
        // when window width is >= 992px
        992: {
            slidesPerView: 3,
            spaceBetween: 20,
        },
    },
});

// eslint-disable-next-line no-unused-vars
const swipermobile = new Swiper('.statistics-homepage-wrap .swiper-container', {
    slidesPerView: 1,
	pagination: {
		el: '.swiper-pagination',
		clickable: true,
	},
    breakpoints: {
        // when window width is >= 768px
        768: {
            slidesPerView: 3,
        },
    },
});
