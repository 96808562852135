function activateFAQs()
{
    const getNextSibling = function (elem, selector) {

        // Get the next sibling element
        var sibling = elem.nextElementSibling;

        // If there's no selector, return the first sibling
        if (!selector) return sibling;

        // If the sibling matches our selector, use it
        // If not, jump to the next sibling and continue the loop
        while (sibling) {
            if (sibling.matches(selector)) return sibling;
            sibling = sibling.nextElementSibling
        }

    };
    const faqs = document.querySelector('.faqs');

    if (typeof (faqs) != 'undefined' && faqs != null) {
        const acc = document.getElementsByClassName('accordion-title');

        for (let i = 0; i < acc.length; i++) {
            acc[i].addEventListener('click', function (e) {
                e.preventDefault();
                this.parentNode.classList.toggle('is-active');
                const panel = getNextSibling(this, '.accordion-content');
                if (panel.style.maxHeight) {
                    panel.style.maxHeight = null;
                    this.setAttribute('data-direction', 'close');
                } else {
                    panel.style.maxHeight = panel.scrollHeight + 'px';
                    this.setAttribute('data-direction', 'open');
                }
            });
        }
    }
}

export default function init()
{
    activateFAQs();
}
