// Function to handle the rating tip toggle and interactions
function handleRatingTip() {
    var infoElements = document.querySelectorAll('.securescore-rate.mobile .score .score-rating-info');
    var ratingTip = document.querySelector('.score-rating-tip');

    if ((typeof infoElements !== 'undefined' && infoElements.length > 0) && 
        (typeof ratingTip !== 'undefined' && ratingTip !== null)) {

        // Toggle rating-tip visibility on info click
        infoElements.forEach(function (infoElement) {
            infoElement.addEventListener('click', function (e) {
                e.stopPropagation();

                if (ratingTip.classList.contains('visible')) {
                    fadeOut(ratingTip);
                } else {
                    fadeIn(ratingTip);
                }
            });
        });

        // Close rating-tip when clicking outside of the info element
        document.body.addEventListener('click', function (e) {
            if (!e.target.classList.contains('score-rating-info') && ratingTip.classList.contains('visible')) {
                fadeOut(ratingTip);
            }
        });

        // Hide rating-tip when scrolling
        window.addEventListener('scroll', function () {
            var top = window.scrollY,
                elementTop = ratingTip.getBoundingClientRect().top + window.scrollY,
                elementHeight = ratingTip.offsetHeight;

            if (top > (elementTop + elementHeight) && ratingTip.classList.contains('visible')) {
                fadeOut(ratingTip);
            }
        });
    }

    // Helper function to fade in an element
    function fadeIn(element) {
        element.style.opacity = 0;
        element.style.display = 'block';
        element.classList.add('visible');
        let opacity = 0;
        const interval = setInterval(function () {
            if (opacity >= 1) {
                clearInterval(interval);
            }
            element.style.opacity = opacity;
            opacity += 0.1;
        }, 50); // Adjust the duration as needed
    }

    // Helper function to fade out an element
    function fadeOut(element) {
        let opacity = 1;
        const interval = setInterval(function () {
            if (opacity <= 0) {
                clearInterval(interval);
                element.style.display = 'none';
                element.classList.remove('visible');
            }
            element.style.opacity = opacity;
            opacity -= 0.1;
        }, 50); // Adjust the duration as needed
    }
}

// Exported function to initialize all required functionality
export default function init() {
    handleRatingTip();
}