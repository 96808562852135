function addUrlParams() {
    function addParams(btn, type) {

        const absolutePath = function (href) {
            const link = document.createElement('a');
            link.href = href;
            return link.href;
        };

        let url = btn.getAttribute('href'),
            params = new URLSearchParams(),
            ringpool = btn.getAttribute('data-ringpoolid'),
            dnis = btn.getAttribute('data-dnis'),
            phone = btn.getAttribute('data-phone'),
            urlObj = new URL(absolutePath(url));

        console.log(url);

        urlObj.search = '';
        url = urlObj.toString();

        if (type === 'enter') {
            if (typeof ringpool !== 'undefined' && ringpool !== null) {
                params.set('ringpool', ringpool);
            }
            if (typeof dnis !== 'undefined' && dnis !== null) {
                params.set('dnis', dnis);
            }
            if (typeof phone !== 'undefined' && phone !== null) {
                params.set('phone', phone);
            }
            const query = params.toString();
            if (query.length) {
                btn.setAttribute('href', url + '?' + query);
            }
        } else {
            // Remove query string if present
            btn.setAttribute('href', url);
        }
    }

    const btnElement = '[data-ga="referral-cta"]';

    function handlePointerEnter(event) {
        const target = event.target;
        if (target.matches(btnElement + ' *')) {
            // handle pointer enter on child elements
            addParams(target.parentElement, 'enter');
        } else if (target.matches(btnElement)) {
            // handle pointer enter on parent element
            addParams(target, 'enter');
        }
    }

    function handlePointerLeave(event) {
        const target = event.target;
        if (target.matches(btnElement + ' *')) {
            // handle pointer leave on child elements
            addParams(target.parentElement, 'leave');
        } else if (target.matches(btnElement)) {
            // handle pointer leave on parent element
            addParams(target, 'leave');
        }
    }

    document.addEventListener('mouseover', handlePointerEnter);
    document.addEventListener('touchstart', handlePointerEnter);
    document.addEventListener('mouseout', handlePointerLeave);
    document.addEventListener('touchend', handlePointerLeave);

}

export default function init() {
    document.addEventListener('DOMContentLoaded', () => {
        addUrlParams();
    });
}

